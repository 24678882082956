import React from 'react'
import {useTranslation} from 'next-i18next'
import TagText from '@component/Text/TagText'
import ReferralIcon from '@svg/referral/ic_screen_login_referral_banner.svg'

interface IProps {
    className?: string
}

const ScreenLoginReferralBanner: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    return (
        <div
            className={`bg-primary_tint02 dark:bg-dark_primary_tint02 p-[10px] flex items-center gap-x-[10px] ${className} rounded-[5px]`}>
            <ReferralIcon className={'flex-none'} />
            <TagText className={'text-body2 text-gray02 dark:text-dark_gray02 flex-1'}>
                {t('loginModal.login.referralBanner.text')}
            </TagText>
        </div>
    )
}

export default ScreenLoginReferralBanner
