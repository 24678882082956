import React, {useEffect, useImperativeHandle, useMemo, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import ic_pending_invoice_img from '@image/pending_invoice_img.png'
import WizButton from '@component/buttons/WizButton'
import useNavigation from '@hook/useNavigation'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import useFormatDate from '@hook/useFormatDate'

interface IProps {
    className?: string
    id?: string
}

const PendingInvoiceModal = ({id}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()
    const {goAccountInvoice} = useNavigation()
    const {displayDateFromText} = useFormatDate()

    const {data: pendingInvoiceData} = useQueryGetPendingInvoices()

    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
        close: () => {
            modalRef.current.close()
        },
    }))
    const renderInvoice = useMemo(() => {
        return pendingInvoiceData
            ?.sort((a, b) => new Date(a?.due_at)?.getTime() - new Date(b?.due_at)?.getTime())
            ?.at(0)
    }, [pendingInvoiceData])

    return (
        <WizContentModal size={'400'} ref={modalRef}>
            <>
                <div className={'flex'}>
                    <div className={'flex-1 space-y-[5px]'}>
                        <Text className={'text-h3 text-gray01 dark:text-dark_gray01'}>
                            {t('modal.invoiceNotification.unpaid.title')}
                        </Text>
                        <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                            {t('modal.invoiceNotification.unpaid.time', {
                                date: displayDateFromText(renderInvoice?.due_at, t('dateFormat.dateAndTime')),
                            })}
                        </Text>
                    </div>
                    <div className={'flex-none'}>
                        <WizImage src={ic_pending_invoice_img} containerClassName={'w-[100px] ml-auto'} />
                    </div>
                </div>
                <div className={'mt-[10px]'}>
                    <Text className={'text-body2 text-gray02 dark:text-dark_gray02'}>
                        {t('modal.invoiceNotification.unpaid.content')}
                    </Text>
                </div>
                <div className={'mt-[20px]'}>
                    <WizButton
                        text={t('modal.invoiceNotification.unpaid.button.text')}
                        buttonType={'primary'}
                        onClick={() => {
                            modalRef?.current?.close()
                            goAccountInvoice()
                        }}
                        className={'w-full'}
                    />
                </div>
            </>
        </WizContentModal>
    )
}

export default React.forwardRef(PendingInvoiceModal)
