import React, {useContext, useEffect, useMemo, useState} from 'react'
import {ILoginModalProps} from '@feature/common/components/LoginModal/LoginModalRouter'
import Text from '@component/Text/Text'
import LoginModalLayout from '@feature/common/components/LoginModal/layout/loginModalLayout'
import {useTranslation} from 'next-i18next'
import WizInputText, {InputState} from '@component/inputs/WizInputText'
import WizButton from '@component/buttons/WizButton'
import {NICKNAME_MAX_LENGTH, NICKNAME_MIN_LENGTH} from '@constant/auth'
import InputTextCount from '@component/inputs/InputTextCount'
import {useLocation, useNavigate} from 'react-router-dom'
import {RegisterContext} from '@feature/common/components/LoginModal/routes/RegisterRoutes/RegisterRoutes'
import {equalString, isEmpty} from '@util/strings'
import {ENGLISH_NICKNAME_FORBIDDEN_REGEX} from '@constant/reg'
import {apiCheckUserName} from '@api/auths/register/checkUserName'
import useSnackbar from '@hook/useSnackbar'
import {apiSendEmailConfirmCode} from '@api/auths/register/sendEmailConfirmCode'
import {createLog} from '@util/logs'

interface IProps extends ILoginModalProps {}

const ScreenRegisterName: React.FC<IProps> = ({closeModal}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()

    let {state} = useLocation()
    const {email: emailReceived} = state
    const [isLoading, setIsLoading] = useState(false)
    const {showErrorSnackbar, showSnackbar} = useSnackbar()

    const {email, setEmail, name, setName, code, setCode, setPassword, setConfirmPassword} = useContext(RegisterContext)

    const [inputState, setInputState] = useState<InputState>(InputState.DEFAULT)
    const [inputErrorMessage, setInputErrorMessage] = useState('')

    useEffect(() => {
        if (!equalString(emailReceived, email)) {
            setEmail(emailReceived)
            setName('')
            setCode('')
            setPassword('')
            setConfirmPassword('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailReceived])

    const isDisableSubmitButton = useMemo(
        () =>
            isEmpty(name) ||
            name?.length < NICKNAME_MIN_LENGTH ||
            name?.length > NICKNAME_MAX_LENGTH ||
            inputState === InputState.ERROR,
        [name, inputState],
    )

    const onClickSubmit = async () => {
        createLog('event', 'login_email_register_name_submit')
        if (isDisableSubmitButton) return

        setIsLoading(true)
        const {data, error} = await apiCheckUserName(name)
        if (data) {
            if (data?.can_use === true) {
                if (isEmpty(code)) {
                    await apiSendEmailConfirmCode(email)
                }
                navigate('/register_code')
            } else {
                setInputState(InputState.ERROR)
                setInputErrorMessage(t('loginModal.registerName.submit.res.fail.nameDuplication'))
                createLog('error', 'login_email_register_name_submit_failed_nameDuplication')
            }
        } else {
            showErrorSnackbar(error)
            createLog('error', 'login_email_register_name_submit_failed')
        }
        setIsLoading(false)
    }

    const onChangedInputText = (text?: string) => {
        setName(text)
        if (ENGLISH_NICKNAME_FORBIDDEN_REGEX.test(text)) {
            setInputState(InputState.ERROR)
        } else {
            setInputErrorMessage(t('loginModal.registerName.name.input.description'))
            setInputState(InputState.DEFAULT)
        }
    }

    return (
        <LoginModalLayout closeModal={closeModal} title={t('loginModal.registerName.page.title')}>
            <Text className={'text-body1 text-gray02 dark:text-dark_gray02 break-words'}>
                {t('loginModal.registerName.desc', {email: email.length > 30 ? email.slice(0, 30) + '...' : email})}
            </Text>
            <Text className={'text-ti2 text-gray02 dark:text-dark_gray02 mt-[10px]'}>
                {t('loginModal.registerName.name.label')}
            </Text>
            <WizInputText
                text={name}
                className={'mt-[5px]'}
                state={inputState}
                autoFocus={true}
                errorDescription={inputErrorMessage}
                placeholder={t('loginModal.registerName.name.input.placeholder')}
                defaultDescription={t('loginModal.registerName.name.input.description')}
                renderInputRightArea={
                    <InputTextCount text={name} maxCount={NICKNAME_MAX_LENGTH} minLength={NICKNAME_MIN_LENGTH} />
                }
                onChanged={onChangedInputText}
                onPressEnter={onClickSubmit}
            />
            <WizButton
                buttonType={'primary'}
                text={t('loginModal.registerName.submit.text')}
                disabled={isDisableSubmitButton}
                className={'mt-[10px] w-full'}
                onClick={onClickSubmit}
                isLoading={isLoading}
            />
        </LoginModalLayout>
    )
}

export default ScreenRegisterName
